import firebase from 'firebase';
import API from './utils/API';
import '@firebase/messaging';

var firebaseConfig;
var messaging;

if (process.env.NODE_ENV === "production") {
  firebaseConfig = {
    apiKey: "AIzaSyACrQSDvvnOvDhb-1czCU79Pn4zI3G5kxA",
    authDomain: "avenue-live-bf3eb.firebaseapp.com",
    databaseURL: "https://avenue-live-bf3eb.firebaseio.com",
    projectId: "avenue-live-bf3eb",
    storageBucket: "avenue-live-bf3eb.appspot.com",
    messagingSenderId: "872599658950",
    appId: "1:872599658950:web:fa0b2e1395075ec12112c7",
    measurementId: "G-8YK8VYM8EJ"
  };
} else {
  firebaseConfig = {
    apiKey: "AIzaSyD2Vx6qyyeOxO7Arq0vtG9j9OLxheL48KU",
    authDomain: "avenue-dev-ef45a.firebaseapp.com",
    databaseURL: "https://avenue-dev-ef45a.firebaseio.com",
    projectId: "avenue-dev-ef45a",
    storageBucket: "avenue-dev-ef45a.appspot.com",
    messagingSenderId: "1066288133419",
    appId: "1:1066288133419:web:f064df729f4803305c816e",
    measurementId: "G-6YHVKNQ8PC"
  };
}
// Initialize Firebase

export const initializeFirebase = () => {
  firebase.initializeApp(firebaseConfig);
  if (firebase.messaging.isSupported()) {
    messaging = firebase.messaging();
    messaging.onMessage((payload) => {
      const title = payload.notification.title;
      const options = {
        body: payload.notification.body,
        icon: payload.notification.icon,
      };
      if (!("Notification" in window)) {
        console.log("This browser does not support desktop notification");
      }

      else if (Notification.permission === "granted") {
        var notification = new Notification(title, options);
        notification.onclick = function (event) {
          event.preventDefault(); // prevent the browser from focusing the Notification's tab
          window.open(payload.notification.click_action, '_self');
        }
      }

      // Otherwise, we need to ask the user for permission
      else if (Notification.permission !== "denied") {
        Notification.requestPermission().then(function (permission) {
          if (permission === "granted") {
            var notification = new Notification(title, options);
            notification.onclick = function (event) {
              event.preventDefault(); // prevent the browser from focusing the Notification's tab
              window.open(payload.notification.click_action, '_self');
            }

          }
        });
      }
    });
  }
}



export const askForPermissioToReceiveNotifications = async () => {
  try {
    const messaging = firebase.messaging();
    await messaging.requestPermission();
    const token = await messaging.getToken();


    API.patch('users/' + localStorage.getItem('userId'),
      {
        "devicePushToken": token,
        "deviceType": 4
      },
      {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
          'Content-Type': 'application/json',
        }
      })
    return token;
  } catch (error) {
    console.error(error);
  }
}



