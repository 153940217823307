import axios from 'axios';
import createNotification from "../Notify";
import swal from 'sweetalert';
// import { browserHistory } from 'react-router';

export default axios.create({
  baseURL: (process.env.NODE_ENV === 'production') ? 'https://apiv2.askavenue.com/api/v2.0/' : 'https://test-avenue2.askavenue.com/api/v2.0/',
  responseType: "json"
});

export const GlobalHandler = (...params) => {
  if(params.length === 1){
    const response = params[0];
    if(response !== undefined && response!==null){
      const statusCode = response.status ? response.status : 'error';

      if(statusCode >=200 && statusCode<=299){
        let message = '';
        if(statusCode === 201){
          message = response.data.success && response.data.success.message ? response.data.success.message : 'Created successfully.';
        }else{
          message = response.data.success.message;
        }
        createNotification('success',message);
      }else{
        const message = response.data && response.data.errors && response.data.errors.message ? response.data.errors.message : 'Something went wrong. Please try agian later';
        createNotification('error',message);
      }
      const calledUrl = response.config.url.replace(response.config.baseURL, '');
      if(statusCode === 401 && calledUrl !== 'auth/login'){
        swal({
          // title: "Your session has expired",
          text: "Your session has expired",
          icon: "warning",
          closeOnClickOutside: false,
          button: {
            closeModal: true,
          },
          // dangerMode: true,
        })
        .then(willDelete => {
          if (willDelete) {
            localStorage.clear();
            window.location.pathname = '/signin';
          }
        });
      }
    }
    else{
      createNotification('error','Something went wrong. Please try agian later');
    }
  }
  else{
    createNotification(params[0],params[1]);
  }
}

// export const GlobalHandler = (statusCode, message) => {
//
//   message = message ? message : 'Something went wrong. Please try agian later'
//   if(statusCode >=200 && statusCode<=299){
//     createNotification('success',message);
//   }else{
//     createNotification('error',message);
//   }
//   if(statusCode === 401){
//     swal({
//       // title: "Your session has expired",
//       text: "Your session has expired",
//       icon: "warning",
//       closeOnClickOutside: false,
//       button: {
//         closeModal: true,
//       },
//       // dangerMode: true,
//     })
//     .then(willDelete => {
//       if (willDelete) {
//         // swal("Deleted!", "Your imaginary file has been deleted!", "success");
//         window.location.pathname = '/signin';
//       }
//     });
//
//   }
//
// }
