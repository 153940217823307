/**
 * initial auth user
 */

export default (state = localStorage.getItem('userAvatar') || null, action) => {
    switch (action.type) {

        case "USER_AVATAR":
        return action.payload;

        default: return state;
    }
}
