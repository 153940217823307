import { combineReducers } from 'redux';
import unreadConvCountReducer from './unreadConvCountReducer';
import userAvatarReducer from './userAvatarReducer';
import userFirstnameReducer from './userFirstnameReducer';
import userLastnameReducer from './userLastnameReducer';


const allReducers = combineReducers({
  unreadConversationCount: unreadConvCountReducer,
  userAvatar: userAvatarReducer,
  userFirstname: userFirstnameReducer,
  userLastname: userLastnameReducer,
});

export default allReducers;
