import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import {NotificationContainer} from 'react-notifications';
import './App.scss';
import 'react-notifications/lib/notifications.css';
import ReactGA from 'react-ga';
const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;
function initializeReactGA() {
    ReactGA.initialize('UA-123791717-1');
    ReactGA.pageview('/chats');
}
// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));
if(process.env.NODE_ENV === "production") {
    initializeReactGA();
}
// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const RecoveryPassword = React.lazy(() => import('./views/Pages/RecoveryPassword'));
class App extends Component {
  render() {
    return (
      <Router>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route
                render={ props => {
                  return (
                    (localStorage.getItem('authToken') && localStorage.getItem('authToken') === 'true') ? (
                      <Route
                        render={ props => {
                          var chatId = window.location.href.split("?q=");
                          if(chatId[1] !== undefined && chatId[1] !== null && chatId[1].length>0){
                            localStorage.setItem('chatId',chatId[1]);
                          }
                          var url1 = window.location.href.split("?token=");
                          url1 = url1[1];
                          if(url1){
                            var url2 = url1.split('&userId=');
                            var url3 = url2[1].split('&authToken=');
                            localStorage.setItem('accessToken',url2[0]);
                            localStorage.setItem('userId',url3[0]);
                            localStorage.setItem('authToken',url3[1]);
                          }
                          return (<DefaultLayout {...props}/>)
                        }
                      }
                      />
                    ) : (
                      (props.location.pathname === '/signin' || props.location.pathname === '/recover_password') ? (
                        <>
                          <Route exact path="/signin" name="Login Page" render={props => <Login {...props}/>} />
                          <Route exact path="/recover_password" name="Recovery Password" render={props => <RecoveryPassword {...props}/>} />
                        </>
                      ) : (
                        <Redirect to="/signin" />
                      )
                    )
                  )
                }
              }
              />
            </Switch>
          </React.Suspense>
          <NotificationContainer/>
      </Router>
    );
  }
}

export default App;
