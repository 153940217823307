import {NotificationManager} from 'react-notifications';


const createNotification = (type,message) => {
  switch (type) {
    case 'info':
      NotificationManager.info(message);
      break;
    case 'success':
      // NotificationManager.success('Success message', 'Title here');
      NotificationManager.success(message, '');
      break;
    case 'warning':
      NotificationManager.warning(message, '', 5000);
      break;
    // case 'error':
    //   NotificationManager.error('Error message', 'Click me!', 5000, () => {
    //     alert('callback');
    //   });
    case 'error':
      NotificationManager.error(message, '', 5000);
      break;
    default:
      break;
  }
};

export default createNotification;
